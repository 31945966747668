<template>
  <section
    id="archive"
    name="archive"
    class="section archive-section"
  >
    <div class="d-block mb-4">
      <h1 class="text-lighter pt-5">
        Archive
      </h1>
      <p class="subtitle mb-5">
        A big list of things I&rsquo;ve worked on
      </p>
    </div>
    <div class="row w-100">
      <div
        id="archives"
        class="col-48 mb-5"
      >
        <table style="width: 100%">
          <thead>
            <tr>
              <th>Year</th>
              <th>Title</th>
              <th>Made at</th>
              <th>Built with</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody class="archive-tbody">
            <tr
              v-for="post in posts"
              :key="post.uuid"
            >
              <td>
                <div class="archive-cell">
                  <span class="archive-year">{{ post.year }}</span>
                </div>
              </td>
              <td>
                <div class="archive-cell">
                  <span class="archive-title">{{ post.title }}</span>
                </div>
              </td>
              <td>
                <div class="archive-cell">
                  <span class="archive-client">{{ post.client }}</span>
                </div>
              </td>
              <td>
                <div class="archive-cell">
                  <ul class="list-unstyled d-flex mb-0">
                    <li
                      v-for="(tag,index) in renderTags(post.tags)"
                      :key="index"
                      class="archive-tag"
                    >
                      <span
                        v-if="index > 0"
                        class="separator-dot"
                      >&middot;</span>{{ tag }}
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <div class="archive-cell">
                  <a
                    v-if="post.link"
                    :href="post.link"
                    target="_blank"
                    rel="nofollow"
                    class="mr-3 archive-link"
                  >
                    <iconExpand />
                  </a>
                  <a
                    v-if="post.git"
                    :href="post.git"
                    target="_blank"
                    rel="nofollow"
                    class="archive-link"
                  >
                    <iconGitlab />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
// import iconFolder from '@/assets/svg-folder'
import iconExpand from '@/assets/svg-expand';
import iconGitlab from '@/assets/svg-gitlab';
import md from 'markdown-it';

export default {
  name: 'Archive',
  components: {
    // iconFolder,
    iconExpand,
    iconGitlab
  },
  data: function() {
    return {
    };
  },
  computed: {
    posts() {
      return this.$root.posts;
    },
    loading() {
      return this.$root.loading;
    }
  },
  created: function() {
    document.getElementById('app').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  },
  methods: {
    markdown: function(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return md().render(value);
    },
    renderTags: function(value) {
      if (value !== null && value !== undefined && value.length > 0) {
        return value.split('|');
      }
      return '';
    },
    renderClient: function(value) {
      if (value !== null && value !== undefined && value.length > 0) {
        return value;
      }
      return '&ndash;';
    }
  }
};
</script>

<style lang="scss" scoped>
.archive-tag {
  font-size: 1.0625rem;
  margin: 0;
  color: rgba(145, 154, 182, 0.65);
  display: inline-flex;
  align-items: center;
}
.separator-dot {
  font-size: 22px;
  margin: 0;
  padding: 0 0.5rem;
  color: rgba(58, 81, 98, 0.65);
  font-weight: 700;
}
.archive-call {
  vertical-align: baseline;
  display: flex;
  align-items: center;
}
</style>
