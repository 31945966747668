var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "section archive-section",
      attrs: { id: "archive", name: "archive" }
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "row w-100" }, [
        _c("div", { staticClass: "col-48 mb-5", attrs: { id: "archives" } }, [
          _c("table", { staticStyle: { width: "100%" } }, [
            _vm._m(1),
            _c(
              "tbody",
              { staticClass: "archive-tbody" },
              _vm._l(_vm.posts, function(post) {
                return _c("tr", { key: post.uuid }, [
                  _c("td", [
                    _c("div", { staticClass: "archive-cell" }, [
                      _c("span", { staticClass: "archive-year" }, [
                        _vm._v(_vm._s(post.year))
                      ])
                    ])
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "archive-cell" }, [
                      _c("span", { staticClass: "archive-title" }, [
                        _vm._v(_vm._s(post.title))
                      ])
                    ])
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "archive-cell" }, [
                      _c("span", { staticClass: "archive-client" }, [
                        _vm._v(_vm._s(post.client))
                      ])
                    ])
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "archive-cell" }, [
                      _c(
                        "ul",
                        { staticClass: "list-unstyled d-flex mb-0" },
                        _vm._l(_vm.renderTags(post.tags), function(tag, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "archive-tag" },
                            [
                              index > 0
                                ? _c("span", { staticClass: "separator-dot" }, [
                                    _vm._v("·")
                                  ])
                                : _vm._e(),
                              _vm._v(_vm._s(tag) + " ")
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "archive-cell" }, [
                      post.link
                        ? _c(
                            "a",
                            {
                              staticClass: "mr-3 archive-link",
                              attrs: {
                                href: post.link,
                                target: "_blank",
                                rel: "nofollow"
                              }
                            },
                            [_c("iconExpand")],
                            1
                          )
                        : _vm._e(),
                      post.git
                        ? _c(
                            "a",
                            {
                              staticClass: "archive-link",
                              attrs: {
                                href: post.git,
                                target: "_blank",
                                rel: "nofollow"
                              }
                            },
                            [_c("iconGitlab")],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-block mb-4" }, [
      _c("h1", { staticClass: "text-lighter pt-5" }, [_vm._v(" Archive ")]),
      _c("p", { staticClass: "subtitle mb-5" }, [
        _vm._v(" A big list of things I’ve worked on ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Year")]),
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Made at")]),
        _c("th", [_vm._v("Built with")]),
        _c("th", [_vm._v("Link")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }